<template>
  <div id="app">
    <div class="card-container">
      <!-- Header -->
      <h1 class="product-header">Glueless wigs for every woman</h1>

      <!-- Media (video or selected image) -->
      <div class="media-container">
        <!-- Show video if no style is selected AND it hasn't ended -->
        <video
          v-if="isVideoVisible"
          ref="productVideo"
          class="vertical-media"
          autoplay
          muted
          loop
          playsinline
          @ended="showImage = true"
        >
          <source src="videos/video.mov" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <!-- Otherwise show an image (after video ends, or if style selected) -->
        <img
          v-else
          class="vertical-media"
          :src="currentImage"
          alt="Product Image"
        />

        <!-- Mute/Unmute button at the bottom-right -->
        <button class="mute-button" @click="toggleMute">
          <!-- Speaker + slash (Muted) -->
          <svg
            v-if="isMuted"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="icon"
          >
            <path d="M11 5L6 9H2v6h4l5 4V5z"></path>
            <line x1="23" y1="9" x2="17" y2="15"></line>
            <line x1="17" y1="9" x2="23" y2="15"></line>
          </svg>

          <!-- Speaker + waves (Unmuted) -->
          <svg
            v-else
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="icon"
          >
            <path d="M11 5L6 9H2v6h4l5 4V5z"></path>
            <path d="M15 9c1.5 1 1.5 5 0 6"></path>
            <path d="M18 8c2 2 2 6 0 8"></path>
          </svg>
        </button>
      </div>

      <!-- Info Section -->
      <div class="info-section">
        <h2 class="product-title">{{ currentTitle }}</h2>
        <p class="description">{{ currentDescription }}</p>
        <h3 class="delivery-date">Get it by: {{ deliveryDate }}</h3>
      </div>

      <!-- Style Thumbnails: horizontally scrollable -->
      <div class="styles-container">
        <p class="select-text">Select from style:</p>
        <div class="styles-scroll">
          <div
            v-for="(style, index) in styles"
            :key="style.id"
            class="style-thumbnail"
            :class="{
              unavailable: !style.available,
              selected: selectedStyleIndex === index
            }"
            @click="selectStyle(index)"
          >
            <!-- Sold Out overlay if unavailable -->
            <div v-if="!style.available" class="sold-out-overlay">Sold Out</div>
            <img :src="style.image" :alt="style.name" />
            <span class="style-name">{{ style.name }}</span>
          </div>
        </div>
      </div>

      <!-- Buy Button -->
      <button class="buy-button" @click="buyNow">Buy Now</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productTitle: "YellowBox By Wanneka",
      description: "Instant glueless-wig, custom designed to get you going in seconds.",
      isMuted: true,
      showImage: false, // set to true when video ends
      deliveryDate: null,

      /* Five sample styles */
      styles: [
        {
          id: 1,
          name: "Style 1",
          image: "videos/style001.jpg",
          description: "Gorgeous bob cut wig with a natural look.",
          available: true
        },
        {
          id: 2,
          name: "Style 2",
          image: "videos/style002.jpg",
          description: "Long straight wig, perfect for a sleek style.",
          available: true
        },
        {
          id: 3,
          name: "Style 3",
          image: "videos/style003.jpg",
          description: "Curly hair wig with lots of volume.",
          available: false
        },
        {
          id: 4,
          name: "Style 4",
          image: "videos/style004.jpg",
          description: "Pixie cut wig for a modern, edgy look.",
          available: true
        },
        {
          id: 5,
          name: "Style 5",
          image: "videos/style005.jpg",
          description: "Wavy wig that adds a natural beachy vibe.",
          available: true
        }
      ],
      selectedStyleIndex: null
    };
  },
  mounted() {
    this.deliveryDate = this.calculateDeliveryDate();
  },
  computed: {
    // Show video if no style is selected and video hasn't ended
    isVideoVisible() {
      return this.selectedStyleIndex === null && !this.showImage;
    },
    // Current image from selected style or fallback
    currentImage() {
      if (this.selectedStyleIndex !== null) {
        return this.styles[this.selectedStyleIndex].image;
      }
      return "videos/style001.jpg"; // fallback once video ends
    },
    // Display either the global or style-specific description
    currentDescription() {
      if (this.selectedStyleIndex !== null) {
        return this.styles[this.selectedStyleIndex].description;
      }
      return this.description;
    },
    // Display either the global title or style-specific title
    currentTitle() {
      if (this.selectedStyleIndex !== null) {
        return this.productTitle + " - " + this.styles[this.selectedStyleIndex].name;
      }
      return this.productTitle;
    }
  },
  methods: {
    toggleMute() {
      const video = this.$refs.productVideo;
      this.isMuted = !this.isMuted;
      if (video) {
        video.muted = this.isMuted;
      }
    },
    selectStyle(index) {
      // If not available, show an alert
      if (!this.styles[index].available) {
        alert("This style is currently sold out.");
        return;
      }
      // Selecting a style hides the video and shows the style's image
      this.selectedStyleIndex = index;
    },
    buyNow() {
      window.location.href = "https://paystack.com/pay/leiusac1ja";
    },
    calculateDeliveryDate() {
      let date = new Date();
      date.setDate(date.getDate() + 2);
      return date.toDateString();
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  background: #f5f5f5;
  overflow-y: auto; /* allow scrolling if needed */
}

#app {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

/* Main card */
.card-container {
  width: 90%;
  max-width: 400px;
  min-height: 100vh;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  padding: 20px 15px;
  margin-bottom: 40px; /* extra space if content extends */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Heading */
.product-header {
  margin: 0;
  font-weight: 700;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
  margin-bottom: 15px;
}

/* Video/image container */
.media-container {
  position: relative;
  width: 100%;
  aspect-ratio: 9/14;
  border-radius: 15px;
  background: #000;
  margin-bottom: 15px;
}

.vertical-media {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

/* Mute/unmute at bottom-right */
.mute-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  padding: 6px;
  cursor: pointer;
  color: #fff;
}

.icon {
  width: 24px;
  height: 24px;
}

/* Info Section */
.info-section {
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
}

.product-title {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 700;
  color: #333;
}

.description {
  margin: 5px 0;
  font-size: 0.9rem;
  color: #666;
}

.delivery-date {
  margin-top: 5px;
  font-size: 1rem;
  color: #000;
  font-weight: 600;
}

/* Thumbnail Styles Section */
.styles-container {
  width: 100%;
  margin-bottom: 15px;
}

.select-text {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
  text-align: left;
  padding-left: 5px;
}

/* Horizontally scrollable container */
.styles-scroll {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  padding: 5px;
}

.style-thumbnail {
  min-width: 70px;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.style-thumbnail img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 8px;
  border: 2px solid transparent;
}

/* Highlight selected style */
.style-thumbnail.selected img {
  border-color: #ff6600;
}

.style-name {
  font-size: 0.75rem;
  color: #333;
  display: inline-block;
  margin-top: 4px;
}

/* Unavailable style */
.style-thumbnail.unavailable {
  opacity: 0.4;
  pointer-events: none;
}

/* Sold out overlay */
.sold-out-overlay {
  position: absolute;
  top: 25px;
  left: 10px;
  background: rgba(255, 0, 0, 0.7);
  color: #fff;
  font-size: 0.7rem;
  font-weight: 700;
  padding: 2px 4px;
  border-radius: 3px;
}

/* Buy Button */
.buy-button {
  outline: none;
  border: none;
  border-radius: 8px;
  background-color: #ff6600;
  color: #fff;
  font-size: 1rem;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 5px;
}

.buy-button:hover {
  background-color: #e55a00;
}
</style>
